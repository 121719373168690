//------------------------------------------------------------------------------------

const baseUrl = 'https://test.bslcatgvid.in';

// const baseUrl = 'https://bslsntgoalsetter.in';

// const baseUrl = 'http://localhost:8002';

export default baseUrl;

//------------------------------------------------------------------------------------